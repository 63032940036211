import ConfigPaymentGlobal from '../../components/ConfigPaymentGlobal/ConfigPaymentGlobal';
import ConfigHF from '../../components/ConfigHF/ConfigHF';
import { getPaymentModeDraftConfig } from '../../selectors/configSelectors';
import { connect } from 'react-redux';
import ConfigHPagePayment from '../../components/ConfigHPagePayment/ConfigHPagePayment';

function ConfigPayment(props) {
  return (
    <>
      <ConfigPaymentGlobal />
      {props.payment_mode.mode === 'HPage' ? (
        <ConfigHPagePayment />
      ) : (
        <ConfigHF />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    payment_mode: getPaymentModeDraftConfig(state)
  };
};

export default connect(mapStateToProps, null)(ConfigPayment);
