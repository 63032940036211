import './Header.scss';

import React, { useState, forwardRef } from 'react';
import { connect } from 'react-redux';
import { Routes, Route, Link, NavLink } from 'react-router-dom';

import SvgIcon from '@mui/material/SvgIcon';
import { HiButton } from '@hipay/design-system/components';

import { getLanguages } from '../../selectors/appSelectors';
import { getClientConfig } from '../../selectors/configSelectors';
import { updateLanguage } from '../../actions/intlActions';
import { FormattedMessage } from 'react-intl';

function Header(props) {
  const [isLangOpen, toggleLangOpen] = useState(false);

  // Toggle lang selector
  const toggleLang = () => toggleLangOpen(!isLangOpen);

  // Update lang
  const selectLang = (lang) => {
    props.updateLanguage(lang);
    toggleLangOpen(false);
  };

  // Block UI if HiPay is not defined
  const isHiPayDefined = global.hasOwnProperty('HiPay') && !!global.HiPay;

  return (
    <div className={'Header ' + (isLangOpen ? 'lang-opened' : '')}>
      <div className="nav-link">
        <Routes>
          <Route
            path="/"
            exact
            element={
              <HiButton
                id="settings"
                className="uppercase link"
                variant="outlined"
                startIcon={
                  <img
                    src={`${process.env.PUBLIC_URL}/static/images/settings.svg`}
                    alt="settings"
                    height="24px "
                    width="24px"
                  />
                }
                component={forwardRef((props, ref) => (
                  <NavLink {...props} to="/settings" ref={ref} />
                ))}
              >
                <FormattedMessage id={'settings'} />
              </HiButton>
            }
          />

          <Route
            path="/*"
            element={
              <HiButton
                id="home"
                className="uppercase link"
                variant="outlined"
                startIcon={
                  <img
                    src={`${process.env.PUBLIC_URL}/static/images/home.svg`}
                    alt="home"
                    height="24px "
                    width="24px"
                  />
                }
                component={forwardRef((props, ref) => (
                  <NavLink {...props} to="/" ref={ref} />
                ))}
                disabled={!isHiPayDefined}
              >
                <FormattedMessage id={'home'} />
              </HiButton>
            }
          />
        </Routes>
      </div>

      <div className="hipay-logo">
        <Link to="/" className={!isHiPayDefined ? 'disabled-link' : ''}>
          <img
            src={`${process.env.PUBLIC_URL}/static/images/hipay-logo.svg`}
            alt="hipay-logo"
            height="40px"
          />
        </Link>
      </div>

      <div className="flags">
        {isLangOpen &&
          props.languages.map((lang) => {
            if (lang === props.lang) {
              return null;
            }
            return (
              <SvgIcon
                key={lang}
                component="div"
                className="flag-icon"
                onClick={() => selectLang(lang)}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/static/images/countries/${lang}.svg`}
                  alt={lang}
                />
              </SvgIcon>
            );
          })}

        <SvgIcon component="div" className="flag-icon" onClick={toggleLang}>
          <img
            src={`${process.env.PUBLIC_URL}/static/images/countries/${props.lang}.svg`}
            alt={props.lang}
            height="40px"
            width="40px"
          />
        </SvgIcon>
      </div>
    </div>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    languages: getLanguages(state),
    lang: getClientConfig(state).lang,
    hasSdkFrontendError: state.appReducer.hasSdkFrontendError
  };
};

export default connect(mapStateToProps, { updateLanguage })(Header);
