import availablePaymentProducts from './available-payment-products.js';

const defaultState = {
  isSdkLoading: false,
  hasSdkFrontendError: false,
  isConfigDrawerOpen: false,
  isDrawerMenuOpen: false,
  isModalOpen: false,
  paymentFormIsValid: false,
  paymentInProgress: false,
  hipayPaymentResponse: null,
  hostedInstanceConfig: {
    template: 'auto',
    selector: 'hosted-form-container'
  },
  availablePaymentProducts: [...availablePaymentProducts],
  languages: ['fr-FR', 'en-GB', 'en-US', 'es-ES', 'it-IT', 'pt-BR', 'es-MX'],
  cartItemsStage: [
    {
      id: 1,
      name: 'Short',
      imageUrl: 'short.svg',
      quantity: 1,
      price: 40
    },
    {
      id: 2,
      name: 'Shirt',
      imageUrl: 'shirt.svg',
      quantity: 1,
      price: 30
    },
    {
      id: 3,
      name: 'shipping',
      imageUrl: 'shipping.svg',
      quantity: 1,
      price: 30
    }
  ],
  cartItemsProduction: [
    {
      id: 1,
      name: 'Short',
      imageUrl: 'short.svg',
      quantity: 1,
      price: 0.4
    },
    {
      id: 2,
      name: 'Shirt',
      imageUrl: 'shirt.svg',
      quantity: 1,
      price: 0.3
    },
    {
      id: 3,
      name: 'shipping',
      imageUrl: 'shipping.svg',
      quantity: 1,
      price: 0.3
    }
  ]
};

const getInitialState = () => {
  // Return initial state
  return defaultState;
};

// Get initial state
const initialState = getInitialState();

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLOSE_DRAWER_MENU':
      return { ...state, isDrawerMenuOpen: false };

    case 'OPEN_DRAWER_MENU':
      return { ...state, isDrawerMenuOpen: true };

    case 'UPDATE_PAYMENT_FORM_VALIDITY':
      return {
        ...state,
        paymentFormIsValid: action.valid
      };

    case 'SET_PAYMENT_IN_PROGRESS':
      return {
        ...state,
        paymentInProgress: action.value
      };

    case 'UPDATE_FRONTEND_SDK_CONFIG':
      return {
        ...state,
        hasSdkFrontendError: false
      };

    case 'SET_SDK_LOADING':
      return {
        ...state,
        isSdkLoading: action.isLoading,
        hasSdkFrontendError: action.error
      };
    default:
      return state;
  }
};

export default appReducer;
