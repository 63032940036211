export const getAvailablePaymentProducts = (state) => {
  return state.appReducer.availablePaymentProducts;
};

export const getLanguages = (state) => {
  return state.appReducer.languages;
};

export const getCartItems = (state) => {
  if (state.sdkConfigReducer.config.client.environment === 'production') {
    return state.appReducer.cartItemsProduction;
  } else {
    return state.appReducer.cartItemsStage;
  }
};

export const getCartTotal = (state) => {
  let cartItems = null;
  if (state.sdkConfigReducer.config.client.environment === 'production') {
    cartItems = state.appReducer.cartItemsProduction;
  } else {
    cartItems = state.appReducer.cartItemsStage;
  }

  return cartItems.reduce((res, item) => {
    return res + item.price * item.quantity;
  }, 0);
};

export const isSdkLoading = (state) => {
  return state.appReducer.isSdkLoading;
};
