import './PaymentDetails.scss';

import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import { getPaymentModeConfig } from '../../selectors/configSelectors';

import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

import HostedForm from './../../components/HostedForm/HostedForm';
import HostedPaymentRequestButton from '../../components/HostedPaymentRequestButton/HostedPaymentRequestButton';

function PaymentDetails(props) {
  return (
    <div className='PaymentDetails'>
      <Typography variant='body1' className='payment-details-title'>
        <FormattedMessage id={'payment-details'} />
      </Typography>

      {props.payment_mode.mode === 'HF' && (
        <div className='hosted-payment-form'>
          <HostedPaymentRequestButton />

          <RadioGroup
            className="radioGroup"
            aria-label="payment mode"
            name="payment_mode"
            value={'2'}
          >
            <FormControlLabel
              className="radioLabel"
              value={'1'}
              control={<Radio color="primary" />}
              label={props.intl.formatMessage({ id: 'pay-by-bankwire' })}
              disabled
            />
            <FormControlLabel
              className="radioLabel"
              value={'2'}
              control={<Radio color="primary" />}
              label={props.intl.formatMessage(
                { id: 'pay-by-payment_product' },
                {
                  payment_product: props.intl.formatMessage({
                    id: props.payment_mode.method
                  })
                }
              )}
            />
          </RadioGroup>
        </div>
      )}

      {props.payment_mode.mode !== 'HPage' && (
        <form className="hipay-form" id="hipay-form">
          <HostedForm />
        </form>
      )}
    </div>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    payment_mode: getPaymentModeConfig(state)
  };
};

export default connect(mapStateToProps, null)(injectIntl(PaymentDetails));
