import { createRoot } from 'react-dom/client';

import { Provider } from 'react-intl-redux';
import store from './store';

import './index.css';
import App from './containers/App/App';
import * as serviceWorker from './serviceWorker';

import { DesignSystemProvider } from '@hipay/design-system/context';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <DesignSystemProvider>
      <App />
    </DesignSystemProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
