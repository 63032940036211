import { setSdkLoading } from '../actions/uiActions';

const sdkjsUrls = {
  production: 'https://libs.hipay.com/js/sdkjs.js',
  stage: 'https://stage-libs.hipay.com/js/sdkjs.js'
};

const sdkLoader = (store) => (next) => (action) => {
  // If not Save SDK settings, skip middleware
  if (action.type !== 'SAVE_SDK_SETTINGS') {
    return next(action);
  }

  // Get existing SDK JS
  const existingScript = document.getElementById('hipay-sdk-js');

  // Get target config
  const sdk_frontend_config =
    store.getState().sdkConfigReducer.draftConfig.sdk_frontend;

  // Get targeted environment
  const targetEnv = sdk_frontend_config.environment;
  let targetUrl;
  if (targetEnv !== 'custom') {
    // Select correct env url
    targetUrl = sdkjsUrls[`${targetEnv}`];
  } else {
    // If no custom Url, load stage
    targetUrl = sdk_frontend_config.customUrl
      ? sdk_frontend_config.customUrl
      : sdkjsUrls['stage'];
  }

  // If targetUrl = loaded url, skip middleware
  if (!existingScript || existingScript.src === targetUrl) {
    return next(action);
  }

  // If no same url, start loading new SDK
  // Set flag
  next(setSdkLoading(true));
  next(action);

  // Remove old script
  existingScript.parentNode.removeChild(existingScript);
  // Reset HiPay variable
  global.HiPay = null;
  window.hipay = null;

  // Create new script
  let newScript = document.createElement('script');
  newScript.src = targetUrl;
  newScript.id = 'hipay-sdk-js';

  // Add new script to the page
  document.head.appendChild(newScript);

  // On load
  newScript.onload = () => {
    return next(setSdkLoading(false));
  };

  // On error
  newScript.onerror = () => {
    return next(setSdkLoading(false, true));
  };
};

export default sdkLoader;
