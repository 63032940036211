import './Settings.scss';

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { HiButton } from '@hipay/design-system/components';

import {
  savePaymentMode,
  saveSdkSettings,
  savePaymentSettings,
  saveStylesSettings,
  resetSettings
} from '../../actions/sdkConfigActions.js';

import { isSdkSettingsValid } from '../../selectors/configSelectors';
import { isSdkLoading } from '../../selectors/appSelectors';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';

import ConfigIntegrationType from '../SettingsTabs/ConfigIntegrationType';
import ConfigPayment from '../SettingsTabs/ConfigPayment';
import ConfigStyles from '../SettingsTabs/ConfigStyles';
import ConfigEnvs from '../SettingsTabs/ConfigEnvs';

const describedby = {
  'aria-describedby': 'message-id'
};

function Settings(props) {
  // Init default Tab selected
  const [tabToDisplay, setTab] = useState(0);

  // Init snackbars
  const [snackbarIntegrationTypeOpen, toggleIntegrationTypeSnackbar] =
    useState(false);
  const [snackbarSdkOpen, toggleSdkSnackbar] = useState(false);
  const [snackbarPaymentOpen, togglePaymentSnackbar] = useState(false);
  const [snackbarStylesOpen, toggleStylesSnackbar] = useState(false);

  // Use effect like componentDidUnmount to reset the settings when leave page
  useEffect(
    () => {
      return () => {
        props.resetSettings();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Handle save button clicked and call correct action
  const handleSaveButtonClicked = () => {
    switch (tabToDisplay) {
      case 0:
        props.savePaymentMode();
        toggleIntegrationTypeSnackbar(true);
        break;
      case 1:
        props.savePaymentSettings();
        togglePaymentSnackbar(true);
        break;
      case 2:
        props.saveStylesSettings();
        toggleStylesSnackbar(true);
        break;
      case 3:
        props.saveSdkSettings();
        toggleSdkSnackbar(true);
        break;
      default:
        break;
    }
  };

  // Close the snackbar
  const handleCloseSnackbar = (tab) => () => {
    switch (tab) {
      case 0:
        toggleIntegrationTypeSnackbar(false);
        break;
      case 1:
        togglePaymentSnackbar(false);
        break;
      case 2:
        toggleStylesSnackbar(false);
        break;
      case 3:
        toggleSdkSnackbar(false);
        break;
      default:
        break;
    }
  };

  return (
    <div className="Settings">
      <div className="tabs">
        <Tabs
          value={tabToDisplay}
          onChange={(event, value) => setTab(value)}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          centered
        >
          <Tab
            className="tab"
            label={<FormattedMessage id={'integration-type'} />}
          />
          <Tab
            className="tab"
            label={<FormattedMessage id={'payment-settings'} />}
          />
          <Tab className="tab" label={<FormattedMessage id={'styles'} />} />
          <Tab
            className="tab"
            label={<FormattedMessage id={'environment'} />}
          />
        </Tabs>
      </div>

      {props.isSdkLoading && (
        <div className='sdkLoading'>
          <div className='loading-container'>
            <LoadingSpinner />
            <br />
            <FormattedMessage id={'sdkjs-loading'} />
          </div>
        </div>
      )}

      <div className="page-content tab-content-container">
        <div className="tab-content">
          {(() => {
            switch (tabToDisplay) {
              case 0:
                return <ConfigIntegrationType />;
              case 1:
                return <ConfigPayment />;
              case 2:
                return <ConfigStyles />;
              case 3:
                return <ConfigEnvs />;
              default:
                return null;
            }
          })()}
        </div>

        <div className="save-row">
          {(() => {
            switch (tabToDisplay) {
              case 0:
                return (
                  <HiButton fullWidth onClick={handleSaveButtonClicked}>
                    <FormattedMessage id={'save-integration-type'} />
                  </HiButton>
                );
              case 1:
                return (
                  <HiButton fullWidth onClick={handleSaveButtonClicked}>
                    <FormattedMessage id={'save-payment-settings'} />
                  </HiButton>
                );
              case 2:
                return (
                  <HiButton fullWidth onClick={handleSaveButtonClicked}>
                    <FormattedMessage id={'save-styles-settings'} />
                  </HiButton>
                );
              case 3:
                return (
                  <HiButton
                    fullWidth
                    onClick={handleSaveButtonClicked}
                    disabled={!props.isSdkSettingsValid}
                  >
                    <FormattedMessage id={'save-environment-settings'} />
                  </HiButton>
                );
              default:
                return null;
            }
          })()}
        </div>
      </div>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={snackbarIntegrationTypeOpen}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar(0)}
        ContentProps={describedby}
        message={<FormattedMessage id={'integration-type-saved'} />}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleCloseSnackbar(0)}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={snackbarPaymentOpen}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar(1)}
        ContentProps={describedby}
        message={<FormattedMessage id={'payment-settings-saved'} />}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleCloseSnackbar(1)}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={snackbarStylesOpen}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar(2)}
        ContentProps={describedby}
        message={<FormattedMessage id={'styles-settings-saved'} />}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleCloseSnackbar(2)}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={snackbarSdkOpen}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar(3)}
        ContentProps={describedby}
        message={<FormattedMessage id={'environment-settings-saved'} />}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleCloseSnackbar(3)}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </div>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    isSdkSettingsValid: isSdkSettingsValid(state),
    isSdkLoading: isSdkLoading(state)
  };
};

export default connect(mapStateToProps, {
  savePaymentMode,
  saveSdkSettings,
  savePaymentSettings,
  saveStylesSettings,
  resetSettings
})(Settings);
