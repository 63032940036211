import './ColorPicker.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ChromePicker } from 'react-color';

function ColorPicker(props) {
  // Init toggle picker state
  const [displayColorPicker, toggleColorPicker] = useState(false);

  // On change, send color value to parent
  let handleChange = (color) => {
    props.onChange(
      `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
    );
  };

  // On click show the picker
  let handleClick = () => {
    toggleColorPicker(true);
  };

  // On close hide the picker
  let handleClose = () => {
    toggleColorPicker(false);
  };

  return (
    <div className="ColorPicker">
      <div className="colorPickerContainer">
        <div className="swatch" onClick={handleClick}>
          <div
            className="color"
            style={{
              background: props.color
            }}
          />
        </div>
        {displayColorPicker ? (
          <div className="popover">
            <div className="cover" onClick={handleClose} />
            <ChromePicker color={props.color} onChange={handleChange} />
          </div>
        ) : null}
      </div>
      <div className="colorPickerLabel">{props.label}</div>
    </div>
  );
}

ColorPicker.propType = {
  // Text to display next to button
  label: PropTypes.string.isRequired,
  // Callback function to send infos on color change
  onChange: PropTypes.func
};

export default ColorPicker;
