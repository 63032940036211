import './FakeCheckoutStep.scss';

import { FormattedMessage } from 'react-intl';

import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';

function FakeCheckoutStep(props) {
  return (
    <div className='FakeCheckoutStep'>
      <Typography variant='body1'>
        <FormattedMessage id={props.name} />
      </Typography>
      <SvgIcon component='div'>
        <img
          src={`${process.env.PUBLIC_URL}/static/images/tick.svg`}
          alt="settings"
          height="100%"
          width="auto"
        />
      </SvgIcon>
    </div>
  );
}

export default FakeCheckoutStep;
