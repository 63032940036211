import './ConfigIntegrationType.scss';

import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { getPaymentModeDraftConfig } from '../../selectors/configSelectors';
import { updatePaymentMode } from '../../actions/sdkConfigActions';
import ConfigEnvHPage from './ConfigEnvs/ConfigEnvHPage';

function ConfigIntegrationType(props) {
  return (
    <div className="ConfigIntegrationType">
      <div
        className={`integration-type ${props.mode === 'HP' ? ' selected' : ''}`}
        onClick={() => props.updatePaymentMode('HP')}
      >
        <h3>
          <div className="text">Hosted Payments</div>
        </h3>
        <img
          src={`${process.env.PUBLIC_URL}/static/images/HostedPayments.png`}
          alt="HostedPayments"
        />

        <div>
          <p>
            <FormattedMessage id={'hosted-payments-text-1'} />
          </p>
          <p>
            <FormattedMessage id={'hosted-payments-text-2'} />
          </p>
        </div>

        <div className="notes">
          <div className="category">
            <div>
              <FormattedMessage id={'simplicity'} />
            </div>
            <div className="dots">
              <div className="dot filled" />
              <div className="dot filled" />
              <div className="dot filled" />
            </div>
          </div>
          <div className="category">
            <div>
              <FormattedMessage id={'personalization'} />
            </div>
            <div className="dots">
              <div className="dot filled" />
              <div className="dot filled" />
              <div className="dot" />
            </div>
          </div>
          <div className="category">
            <div>
              <FormattedMessage id={'security'} />
            </div>
            <div className="dots">
              <div className="dot filled" />
              <div className="dot filled" />
              <div className="dot filled" />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`integration-type ${props.mode === 'HF' ? 'selected' : ''}`}
        onClick={() => props.updatePaymentMode('HF')}
      >
        <h3>
          <div className="text">Hosted Fields</div>
        </h3>
        <img
          src={`${process.env.PUBLIC_URL}/static/images/HostedFields.png`}
          alt="HostedFields"
        />

        <div>
          <p>
            <FormattedMessage id={'hosted-fields-text-1'} />
          </p>
          <p>
            <FormattedMessage id={'hosted-fields-text-2'} />
          </p>
        </div>

        <div className="notes">
          <div className="category">
            <div>
              <FormattedMessage id={'simplicity'} />
            </div>
            <div className="dots">
              <div className="dot filled" />
              <div className="dot filled" />
              <div className="dot" />
            </div>
          </div>
          <div className="category">
            <div>
              <FormattedMessage id={'personalization'} />
            </div>
            <div className="dots">
              <div className="dot filled" />
              <div className="dot filled" />
              <div className="dot filled" />
            </div>
          </div>
          <div className="category">
            <div>
              <FormattedMessage id={'security'} />
            </div>
            <div className="dots">
              <div className="dot filled" />
              <div className="dot filled" />
              <div className="dot filled" />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`integration-type ${
          props.mode === 'HPage' ? ' selected' : ''
        }`}
        onClick={() => props.updatePaymentMode('HPage')}
      >
        <h3>
          <div className="text">Hosted Page</div>
        </h3>
        <img
          src={`${process.env.PUBLIC_URL}/static/images/HostedPage.png`}
          alt="HostedPage"
        />

        <div>
          <p>
            <FormattedMessage id={'hosted-page-text-1'} />
          </p>
          <p>
            <FormattedMessage id={'hosted-page-text-2'} />
          </p>
        </div>

        <div className="notes">
          <div className="category">
            <div>
              <FormattedMessage id={'simplicity'} />
            </div>
            <div className="dots">
              <div className="dot filled" />
              <div className="dot filled" />
              <div className="dot filled" />
            </div>
          </div>
          <div className="category">
            <div>
              <FormattedMessage id={'personalization'} />
            </div>
            <div className="dots">
              <div className="dot filled" />
              <div className="dot filled" />
              <div className="dot" />
            </div>
          </div>
          <div className="category">
            <div>
              <FormattedMessage id={'security'} />
            </div>
            <div className="dots">
              <div className="dot filled" />
              <div className="dot filled" />
              <div className="dot filled" />
            </div>
          </div>
        </div>
      </div>
      <div className="hpage-config">
        {props.payment_mode.mode === 'HPage' && <ConfigEnvHPage />}
      </div>
    </div>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    mode: getPaymentModeDraftConfig(state).mode,
    payment_mode: getPaymentModeDraftConfig(state)
  };
};

export default connect(mapStateToProps, { updatePaymentMode })(
  injectIntl(ConfigIntegrationType)
);
