import './ConfigStyles.scss';

import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Typography } from '@mui/material';

import {
  getStylesDraftConfig,
  getApplePayStylesDraftConfig,
  getPaypalStylesDraftConfig,
  getPaymentModeDraftConfig,
  getPaymentDraftConfig,
  getThemeCode,
  isMethodEnabled,
  getClientConfig,
  isPaypalV2Available
} from '../../selectors/configSelectors';
import {
  updateStylesConfig,
  updateApplePayStylesConfig,
  updatePaypalStylesConfig,
  updateHPageThemeCode,
  updateIsPayPalV2Available
} from '../../actions/sdkConfigActions';

import ColorPicker from '../../components/ColorPicker/ColorPicker';
import {
  HiSelect,
  HiFormControl,
  HiInput,
  HiAlert
} from '@hipay/design-system/components';
import { availablePaypalV2Product } from '../../reducers/available-payment-products';

const fontSizes = ['8px', '10px', '12px', '14px', '16px', '18px', '20px'];
const fontStyles = ['normal', 'italic', 'oblique'];
const textDecorations = [
  'none',
  'underline',
  'overline',
  'line-through',
  'blink'
];
const fontWeights = [
  'normal',
  'bold',
  '100',
  '200',
  '300',
  '400',
  '500',
  '600',
  '700',
  '800',
  '900',
  '1000'
];
const typeApplePayButton = [
  'add-money',
  'book',
  'buy',
  'check-out',
  'contribute',
  'donate',
  'order',
  'plain',
  'reload',
  'rent',
  'subscribe',
  'support',
  'tip'
];
const colorApplePayButton = ['black', 'white', 'white-with-line'];

const colorPaypalButton = ['gold', 'blue', 'black', 'silver', 'white'];
const shapePaypalButton = ['pill', 'rect'];
const labelPaypalButton = ['paypal', 'pay', 'subscribe', 'checkout', 'buynow'];
const heightPaypalButton = { minimum: 25, maximum: 55 };

const themeCodes = [
  {
    theme_code: '3P2ejsEFn05fYkYKfJGN',
    info: 'theme-code-help2-2'
  },
  {
    theme_code: 'Am2w7QRcbcQ116JVrtch',
    info: 'theme-code-help2-3'
  },
  {
    theme_code: 'q4S3RTs5qoaQx1z29kJ6',
    info: 'theme-code-help2-4'
  }
];

const productBuilder = (product) => {
  return {
    id: product,
    label: product
  };
};

const applePayproductBuilderWithIntl = (intl, product) => {
  return {
    id: product,
    label: intl.formatMessage({ id: 'applepay_' + product })
  };
};

const paypalV2productBuilderWithIntl = (intl, product) => {
  return {
    id: product,
    label: intl.formatMessage({ id: 'paypalv2_' + product })
  };
};

const ConfigStyles = (props) => {
  const fontSize_list = fontSizes.map((product) => productBuilder(product));
  const fontStyle_list = fontStyles.map((product) => productBuilder(product));
  const fontWeight_list = fontWeights.map((product) => productBuilder(product));
  const textDecoration_list = textDecorations.map((product) =>
    productBuilder(product)
  );
  const typeApplePayButton_list = typeApplePayButton.map((product) =>
    applePayproductBuilderWithIntl(props.intl, product)
  );
  const colorApplePayButton_list = colorApplePayButton.map((product) =>
    applePayproductBuilderWithIntl(props.intl, product)
  );

  const colorPaypalButton_list = colorPaypalButton.map((product) =>
    paypalV2productBuilderWithIntl(props.intl, product)
  );
  const shapePaypalButton_list = shapePaypalButton.map((product) =>
    paypalV2productBuilderWithIntl(props.intl, product)
  );
  const labelPaypalButton_list = labelPaypalButton.map((product) =>
    paypalV2productBuilderWithIntl(props.intl, product)
  );

  let handleChange = (category, name) => (value) => {
    props.updateStylesConfig(category, name, value);
  };

  let handleApplePayListChange = (name) => (value) => {
    props.updateApplePayStylesConfig(name, value);
  };

  let handlePaypalListChange = (name) => (value) => {
    if (name === 'height') {
      value = Number(value);
      if (value < heightPaypalButton.minimum) {
        value = heightPaypalButton.minimum;
        return value;
      } else if (value > heightPaypalButton.maximum) {
        value = heightPaypalButton.maximum;
        return value;
      }
    }
    props.updatePaypalStylesConfig(name, value);
  };

  let handleThemeCodeChange = (name) => (value) => {
    props.updateHPageThemeCode(name, value);
  };

  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(e.target.innerText);
    let elements = document.getElementsByClassName('copied-msg');
    elements.forEach((element) => {
      element.style.opacity = 0;
    });
    let clickedElement = document.getElementById(
      'copied-msg-' + e.target.innerText
    );
    clickedElement.style.opacity = 1;
    setTimeout(() => {
      clickedElement.style.opacity = 0;
    }, 2000);
  };

  useEffect(() => {
    if (props.isPaypalV2Available === null) {
      availablePaypalV2Product(props.client).then((val) => {
        props.updateIsPayPalV2Available(val);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ConfigStyles">
      <h2>
        <FormattedMessage id={'input-styles'} />
      </h2>
      <div className="colors">
        <div className="formControl">
          <ColorPicker
            label={props.intl.formatMessage({ id: `color-label` })}
            color={props.styles.base.color}
            onChange={handleChange('base', 'color')}
          />
        </div>
        <div className="formControl">
          <ColorPicker
            label={props.intl.formatMessage({ id: `placeholderColor-label` })}
            color={props.styles.base.placeholderColor}
            onChange={handleChange('base', 'placeholderColor')}
          />
        </div>
        <div className="formControl">
          <ColorPicker
            label={props.intl.formatMessage({ id: `iconColor-label` })}
            color={props.styles.base.iconColor}
            onChange={handleChange('base', 'iconColor')}
          />
        </div>

        <div className="formControl">
          <ColorPicker
            label={props.intl.formatMessage({ id: `invalid-color-label` })}
            color={props.styles.invalid.color}
            onChange={handleChange('invalid', 'color')}
          />
        </div>

        <div className="formControl">
          <ColorPicker
            label={props.intl.formatMessage({ id: `valid-color-label` })}
            color={props.styles.valid.color}
            onChange={handleChange('valid', 'color')}
          />
        </div>
      </div>

      <div className="inputs">
        <HiFormControl
          id="fontFamily"
          name="fontFamily"
          label={props.intl.formatMessage({ id: `fontFamily-label` })}
        >
          <HiInput
            value={props.styles.base.fontFamily ?? ''}
            onChange={handleChange('base', 'fontFamily')}
          />
        </HiFormControl>
        <HiFormControl
          id={'fontSize'}
          name={'fontSize'}
          label={props.intl.formatMessage({ id: `fontSize-label` })}
        >
          <HiSelect
            options={fontSize_list}
            value={props.styles.base.fontSize}
            onChange={handleChange('base', 'fontSize')}
            searchable={false}
          />
        </HiFormControl>
        <HiFormControl
          id={'fontStyle'}
          name={'fontStyle'}
          label={props.intl.formatMessage({ id: `fontStyle-label` })}
        >
          <HiSelect
            options={fontStyle_list}
            value={props.styles.base.fontStyle}
            onChange={handleChange('base', 'fontStyle')}
            searchable={false}
          />
        </HiFormControl>
        <HiFormControl
          id={'fontWeight'}
          name={'fontWeight'}
          label={props.intl.formatMessage({ id: `fontWeight-label` })}
        >
          <HiSelect
            options={fontWeight_list}
            value={props.styles.base.fontWeight}
            onChange={handleChange('base', 'fontWeight')}
            searchable={false}
          />
        </HiFormControl>
        <HiFormControl
          id={'textDecoration'}
          name={'textDecoration'}
          label={props.intl.formatMessage({ id: `textDecoration-label` })}
        >
          <HiSelect
            options={textDecoration_list}
            value={props.styles.base.textDecoration}
            onChange={handleChange('base', 'textDecoration')}
            searchable={false}
          />
        </HiFormControl>
      </div>

      {window.ApplePaySession && (
        <div className="applepay">
          <h2>
            <FormattedMessage id={'applepay-styles'} />
          </h2>
          <div className="inputs">
            <HiFormControl
              id={'button-type-applepay'}
              name={'button-type-applepay'}
              label={props.intl.formatMessage({ id: `button-type-applepay` })}
            >
              <HiSelect
                options={typeApplePayButton_list}
                value={props.applePayStyle.type}
                onChange={handleApplePayListChange('type')}
                searchable={false}
              />
            </HiFormControl>

            <HiFormControl
              id={'button-style-applepay'}
              name={'button-style-applepay'}
              label={props.intl.formatMessage({ id: `button-style-applepay` })}
            >
              <HiSelect
                options={colorApplePayButton_list}
                value={props.applePayStyle.color}
                onChange={handleApplePayListChange('color')}
                searchable={false}
              />
            </HiFormControl>
          </div>
        </div>
      )}

      {props.paypalEnabled && (
        <div className="paypal-v2">
          <h2>
            <FormattedMessage id="paypalv2-styles" />
          </h2>
          {props.isPaypalV2Available === false && (
            <HiAlert severity="warning">
              PayPal v2 is not enabled with this account. The following options
              will not have any effect.
            </HiAlert>
          )}
          <div className="inputs">
            <HiFormControl
              id="button-color-paypalv2"
              name="button-color-paypalv2"
              label={props.intl.formatMessage({
                id: 'button-color-paypalv2'
              })}
            >
              <HiSelect
                options={colorPaypalButton_list}
                value={props.paypalButtonStyle.color}
                onChange={handlePaypalListChange('color')}
                searchable={false}
              />
            </HiFormControl>

            <HiFormControl
              id="button-shape-paypalv2"
              name="button-shape-paypalv2"
              label={props.intl.formatMessage({
                id: 'button-shape-paypalv2'
              })}
            >
              <HiSelect
                options={shapePaypalButton_list}
                value={props.paypalButtonStyle.shape}
                onChange={handlePaypalListChange('shape')}
                searchable={false}
              />
            </HiFormControl>

            <HiFormControl
              id="button-label-paypalv2"
              name="button-label-paypalv2"
              label={props.intl.formatMessage({
                id: 'button-label-paypalv2'
              })}
            >
              <HiSelect
                options={labelPaypalButton_list}
                value={props.paypalButtonStyle.label}
                onChange={handlePaypalListChange('label')}
                searchable={false}
              />
            </HiFormControl>

            <HiFormControl
              id="button-height-paypalv2"
              name="button-height-paypalv2"
              label={props.intl.formatMessage({
                id: 'button-height-paypalv2'
              })}
            >
              <HiInput
                type="number"
                value={props.paypalButtonStyle.height}
                onChange={handlePaypalListChange('height')}
              />
            </HiFormControl>
          </div>
        </div>
      )}

      {props.payment_mode.mode === 'HPage' && (
        <>
          <h2>
            <FormattedMessage id={'hpage-style'} />
          </h2>
          <div className="code-theme-container">
            <FormattedMessage id={'theme-code-help1'} />
            <br />
            <div className="code-theme">
              <div className="input">
                <HiFormControl
                  id="theme-code"
                  name="theme-code"
                  label={props.intl.formatMessage({ id: 'theme-code' })}
                >
                  <HiInput
                    value={props.themeCode ?? 'Am2w7QRcbcQ116JVrtch'}
                    onChange={handleThemeCodeChange('theme_code')}
                    placeholder={props.intl.formatMessage({ id: 'theme-code' })}
                  />
                </HiFormControl>
              </div>
              <div className="help">
                <div>
                  <FormattedMessage id={'theme-code-help2-1'} />
                </div>
                <ul>
                  {themeCodes?.map((themeCode) => {
                    return (
                      <li key={themeCode.theme_code}>
                        <Typography
                          className="themeCode"
                          variant="body2"
                          component="div"
                        >
                          <span
                            className="themeCode_text"
                            onClick={copyToClipboard}
                          >
                            {themeCode.theme_code}
                          </span>
                          <div
                            id={'copied-msg-' + themeCode.theme_code}
                            className="copied-msg"
                          >
                            <FormattedMessage id={'theme-code-copied'} />
                          </div>
                          <FormattedMessage id={themeCode.info} />
                        </Typography>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    client: getClientConfig(state),
    styles: getStylesDraftConfig(state),
    applePayStyle: getApplePayStylesDraftConfig(state),
    paypalButtonStyle: getPaypalStylesDraftConfig(state),
    payment_mode: getPaymentModeDraftConfig(state),
    payment: getPaymentDraftConfig(state),
    themeCode: getThemeCode(state),
    paypalEnabled: isMethodEnabled(state, 'paypal'),
    isPaypalV2Available: isPaypalV2Available(state)
  };
};

export default connect(mapStateToProps, {
  updateStylesConfig,
  updateApplePayStylesConfig,
  updatePaypalStylesConfig,
  updateHPageThemeCode,
  updateIsPayPalV2Available
})(injectIntl(ConfigStyles));
