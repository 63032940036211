import './App.scss';

import {
  Navigate,
  createBrowserRouter,
  RouterProvider,
  Outlet
} from 'react-router-dom';

import Header from '../../components/Header/Header';
import Home from '../../containers/Home/Home';
import Settings from '../Settings/Settings';
import PaymentState from '../PaymentState/PaymentState';
import HostedInstanceProvider from '../../contexts/HostedInstanceContext';
import HostedPagePayment from '../HostedPagePayment/HostedPagePayment';

function Layout() {
  return (
    <>
      <Header />

      <div className="app-body">
        <div className="route-content">
          <HostedInstanceProvider>
            <Outlet />
          </HostedInstanceProvider>
        </div>
      </div>
    </>
  );
}

export const router = createBrowserRouter([
  {
    path: '/*',
    Component: Layout,
    children: [
      {
        id: 'home',
        path: '',
        Component: Home
      },
      {
        path: 'settings',
        Component: Settings
      },
      {
        path: 'payment',
        Component: PaymentState
      },
      {
        path: 'hpage_payment',
        Component: HostedPagePayment
      },
      {
        path: '*',
        element: <Navigate to="/" />
      }
    ]
  }
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
