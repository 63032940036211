import './ConfigEnvs.scss';

import ConfigEnvSdkjs from './ConfigEnvs/ConfigEnvSdkjs';
import ConfigEnvApi from './ConfigEnvs/ConfigEnvApi';

function ConfigEnvs(props) {
  return (
    <div className="ConfigEnvs">
      <ConfigEnvSdkjs />
      <ConfigEnvApi />
    </div>
  );
}

export default ConfigEnvs;
