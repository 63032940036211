import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { useHostedInstance } from '../../contexts/HostedInstanceContext';

import {
  getPaymentConfig,
  getPaymentModeConfig,
  getStylesConfig
} from '../../selectors/configSelectors';

function HostedForm(props) {
  const { initHostedInstance } = useHostedInstance();

  useEffect(
    () => {
      // Init instance on mount
      initHostedInstance(
        props.payment_mode.mode === 'HP'
          ? 'hosted-payments'
          : props.payment_mode.method,
        props.config
      );

      // return () => {
      //   // Destroy instance on unmount
      //   destroyHostedInstance();
      // };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <div id="hosted-form-container" />;
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    payment_mode: getPaymentModeConfig(state),
    config: {
      ...state.appReducer.hostedInstanceConfig,
      ...getPaymentConfig(state),
      styles: { ...getStylesConfig(state) }
    }
  };
};

export default connect(mapStateToProps, null)(HostedForm);
