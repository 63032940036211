import './Cart.scss';

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedNumber, FormattedMessage } from 'react-intl';

import { getCartItems, getCartTotal } from '../../selectors/appSelectors';
import { getCurrency } from '../../selectors/configSelectors';

import CartItem from '../../components/CartItem/CartItem';

const Cart = (props) => {
  return (
    <div className="Cart">
      <h3>
        <FormattedMessage id={'order-summary'} />
      </h3>

      <div className="cart-items">
        {props.cartItems.map((product) => {
          return (
            <Fragment key={product.id}>
              <CartItem {...product} currency={props.currency} />
              <div className="divider" />
            </Fragment>
          );
        })}
      </div>

      <div className="total">
        <FormattedMessage className="total-text" id={'total'} />{' '}
        <FormattedNumber
          className="amount"
          value={props.total}
          // eslint-disable-next-line
          style="currency"
          currency={props.currency}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    currency: getCurrency(state),
    cartItems: getCartItems(state),
    total: getCartTotal(state)
  };
};

export default connect(mapStateToProps, null)(Cart);
