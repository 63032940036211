export const savePaymentMode = () => ({
  type: 'SAVE_PAYMENT_MODE'
});

export const saveSdkSettings = () => ({
  type: 'SAVE_SDK_SETTINGS'
});

export const savePaymentSettings = () => ({
  type: 'SAVE_PAYMENT_SETTINGS'
});

export const saveStylesSettings = () => ({
  type: 'SAVE_STYLES_SETTINGS'
});

export const resetSettings = () => ({
  type: 'RESET_SETTINGS'
});

export const updatePaymentMode = (mode, method) => ({
  type: 'UPDATE_PAYMENT_MODE',
  mode: mode,
  method: method
});

export const updatePaymentProductConfig = (
  payment_product,
  field,
  name,
  value
) => ({
  type: 'UPDATE_PAYMENT_PRODUCT_CONFIG',
  payment_product: payment_product,
  field: field,
  name: name,
  value: value
});

export const updateDisplayCancelButton = (name, value) => ({
  type: 'UPDATE_DISPLAY_CANCEL_BUTTON',
  name: name,
  value: value
});

export const updateClientConfig = (name, value) => ({
  type: 'UPDATE_CLIENT_CONFIG',
  name: name,
  value: value
});

export const updateBackendSdkConfig = (name, value) => ({
  type: 'UPDATE_BACKEND_SDK_CONFIG',
  name: name,
  value: value
});

export const updateBackendEnvConfig = (name, value) => ({
  type: 'UPDATE_BACKEND_ENV',
  name: name,
  value: value
});

export const updateFrontendSdkConfig = (name, value) => ({
  type: 'UPDATE_FRONTEND_SDK_CONFIG',
  name: name,
  value: value
});

export const updateStylesConfig = (category, name, value) => ({
  type: 'UPDATE_STYLES_CONFIG',
  category: category,
  name: name,
  value: value
});

export const updateApplePayStylesConfig = (name, value) => ({
  type: 'UPDATE_APPLE_PAY_STYLES_CONFIG',
  name: name,
  value: value
});

export const updatePaypalStylesConfig = (name, value) => ({
  type: 'UPDATE_PAYPAL_V2_STYLES_CONFIG',
  name: name,
  value: value
});

export const updateHPageIFrameConfig = (name, value) => ({
  type: 'UPDATE_HPAGE_IFRAME_CONFIG',
  name: name,
  value: value
});

export const updateHPageThemeCode = (name, value) => ({
  type: 'UPDATE_HPAGE_THEME_CODE',
  name: name,
  value: value
});

export const updateHPageCurrentPaymentData = (name, value) => ({
  type: 'UPDATE_HPAGE_CURRENT_PAYMENT_DATA',
  name: name,
  value: value
});

export const updateIsPayPalV2Available = (value) => ({
  type: 'SET_IS_PAYPAL_V2_AVAILABLE',
  value: value
});

export const updateOneClickSavedCards = (value) => ({
  type: 'UPDATE_ONE_CLICK_SAVED_CARDS',
  value: value
});

export const removeOneClickSavedCardLimit = () => ({
  type: 'REMOVE_ONE_CLICK_SAVED_CARDS_LIMIT'
});
