export const closeDrawerMenu = () => ({
  type: 'CLOSE_DRAWER_MENU'
});
export const openDrawerMenu = () => ({
  type: 'OPEN_DRAWER_MENU'
});

export const updatePaymentFormValidity = (isValid) => ({
  type: 'UPDATE_PAYMENT_FORM_VALIDITY',
  valid: isValid
});

export const setPaymentInProgress = (inProgress) => ({
  type: 'SET_PAYMENT_IN_PROGRESS',
  value: inProgress
});

export const setSdkLoading = (isLoading, error = false) => ({
  type: 'SET_SDK_LOADING',
  isLoading: isLoading,
  error: error
});
