import './ConfigPaymentGlobal.scss';

import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  getPaymentModeDraftConfig,
  getCarouselDraftConfig
} from '../../selectors/configSelectors';
import { getAvailablePaymentProducts } from '../../selectors/appSelectors';

import {
  updatePaymentMode,
  updatePaymentProductConfig
} from '../../actions/sdkConfigActions';

import { HiSelect, HiFormControl } from '@hipay/design-system/components';

function ConfigPaymentGlobal(props) {
  const payment_products_list = props.payment_products_available.map(
    (product) => {
      return {
        id: product,
        label: `${props.intl.formatMessage({ id: product })} (${product})`
      };
    }
  );

  const handlePaymentMethodChange = (value) => {
    props.updatePaymentMode('HF', value);
  };

  const handleCarouselConfigChange = (name) => (value) => {
    props.updatePaymentProductConfig('carousel', 'option', name, value);
  };

  return (
    <div className="ConfigPaymentGlobal">
      <h2>
        <FormattedMessage id={'payment-product'} />
      </h2>

      <div className="container">
        <div className="select">
          {props.payment_mode.mode === 'HF' && (
            <HiFormControl
              id={'HF_method_selector'}
              name={'HF_method_selector'}
              style={{ width: '100%' }}
            >
              <HiSelect
                options={payment_products_list}
                value={props.payment_mode.method}
                onChange={handlePaymentMethodChange}
                searchable={false}
              />
            </HiFormControl>
          )}

          {(props.payment_mode.mode === 'HP' ||
            props.payment_mode.mode === 'HPage') && (
            <HiFormControl
              id={'HP_method_filter'}
              name={'HP_method_filter'}
              helperText={props.intl.formatMessage({
                id: 'select-payment-product'
              })}
              style={{ width: '100%' }}
            >
              <HiSelect
                value={props.carousel.payment_product}
                onChange={handleCarouselConfigChange('payment_product')}
                options={payment_products_list}
                multiple={true}
                searchable={false}
                translations={{
                  all: props.intl.formatMessage({ id: 'select-all' }),
                  n_items_selected: props.intl.formatMessage({
                    id: 'n-item-selected'
                  })
                }}
              />
            </HiFormControl>
          )}
        </div>
        <div className="help-text">
          {props.payment_mode.mode === 'HF' && (
            <div>
              <FormattedMessage id={'choose-HF-payment-product'} />
            </div>
          )}
          {props.payment_mode.mode === 'HP' && (
            <div>
              <FormattedMessage id={'choose-HP-payment-product'} />
              <br />
              <FormattedMessage id={'choose-HP-none-tips'} />
            </div>
          )}
          {props.payment_mode.mode === 'HPage' && (
            <div>
              <FormattedMessage id={'choose-HPage-payment-product'} />
              <br />
              <FormattedMessage id={'choose-HP-none-tips'} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    payment_products_available: getAvailablePaymentProducts(state),
    payment_mode: getPaymentModeDraftConfig(state),
    carousel: getCarouselDraftConfig(state)
  };
};

export default connect(mapStateToProps, {
  updatePaymentMode,
  updatePaymentProductConfig
})(injectIntl(ConfigPaymentGlobal));
