// Accepted translations
const TRANSLATIONS_WHITE_LIST = ['en-GB', 'fr-FR'];

// Translations object
const translations = {};

// Load json file dynamically
const loadTranslations = (lang) => {
  // eslint-disable-next-line security/detect-non-literal-require
  translations[`${lang}`] = require(`./../i18n/${lang}.json`);
};

// Get translated keys
const getTranslations = (lang) => {
  // If no translations for this country, return english
  if (!TRANSLATIONS_WHITE_LIST.includes(lang)) {
    lang = 'en-GB';
  }

  // If not loaded yet, load json file
  if (!translations.hasOwnProperty(lang)) {
    loadTranslations(lang);
  }

  // Return translations
  return translations[`${lang}`];
};

// Default state
const defaultState = {
  locale: 'fr-FR',
  messages: getTranslations('fr-FR')
};

const getInitialState = () => {
  // Return initial state
  return defaultState;
};

// Reducer
const intlReducer = (state = getInitialState(), action) => {
  if (action.type === 'UPDATE_LANGUAGE') {
    return {
      ...state,
      locale: action.lang,
      messages: getTranslations(action.lang)
    };
  }

  return state;
};

export default intlReducer;
