import React, { createContext, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { updatePaymentFormValidity } from '../actions/uiActions';

// Create new Context
// It contains Provider and Consumer
export const HostedInstanceContext = createContext();

// Hook function to use Hosted Instance from context
export function useHostedInstance() {
  const context = useContext(HostedInstanceContext);

  if (!context) {
    throw new Error(
      'useHostedInstance must be used within a HostedInstanceProvider'
    );
  }

  const {
    hostedInstance,
    paymentRequestButtonInstance,
    applePayHipayTokenInstance,
    paypalHipayTokenInstance,
    setApplePayHipayTokenInstance,
    setPaypalHipayTokenInstance,
    initHostedInstance,
    destroyHostedInstance
  } = context;

  return {
    hostedInstance,
    paymentRequestButtonInstance,
    applePayHipayTokenInstance,
    paypalHipayTokenInstance,
    setApplePayHipayTokenInstance,
    setPaypalHipayTokenInstance,
    initHostedInstance,
    destroyHostedInstance
  };
}

// HostedInstanceProvider that will render the Provider Context
function HostedInstanceProvider(props) {
  if (!window.hasOwnProperty('hipay')) {
    throw new Error('HiPay SDK is not initialised');
  }
  // Hosted Instance (Hosted Fields or Hosted Payments)
  const [hostedInstance, setInstance] = useState(null);
  let navigate = useNavigate();

  // Payment Request Button (Only used by Hosted Fields)
  const [paymentRequestButtonInstance, setPaymentRequestButtonInstance] =
    useState(null);

  // Token Apple Pay Instance
  const [applePayHipayTokenInstance, setApplePayHipayTokenInstance] =
    useState(null);

  const [paypalHipayTokenInstance, setPaypalHipayTokenInstance] =
    useState(null);

  // Use effect like componentDidUnmount to reset the settings when leave page
  useEffect(
    () => {
      if (hostedInstance) {
        // Hosted Fields instances has type property, not Hosted Payments
        if (!hostedInstance.hasOwnProperty('hostedPaymentsInstance')) {
          // Listen to change event to handle form validity for Hosted Fields
          hostedInstance.on('change', function (data) {
            props.updatePaymentFormValidity(data.valid);
          });
        } else {
          // Listen to paymentProductChange to always disable payment button onChange
          hostedInstance.on('paymentProductChange', function () {
            props.updatePaymentFormValidity(false);
          });

          // Listen to validityChange to handle form validity for Hosted Payments
          hostedInstance.on('validityChange', function (data) {
            props.updatePaymentFormValidity(data.valid);
          });
        }

        hostedInstance.on('paymentAuthorized', function (tokenHipay) {
          if (
            hostedInstance.currentPaymentProduct === 'paypal' ||
            hostedInstance.type === 'paypal'
          ) {
            setPaypalHipayTokenInstance(tokenHipay);
          } else {
            setApplePayHipayTokenInstance(tokenHipay);
          }
          navigate('/payment');
        });

        hostedInstance.on('paymentUnauthorized', function (error) {
          if (
            hostedInstance.currentPaymentProduct === 'paypal' ||
            hostedInstance.type === 'paypal'
          ) {
            console.log('HP PayPal v2 paymentUnauthorized', error);
          } else {
            console.log('HP ApplePay paymentUnauthorized', error);
          }
        });

        hostedInstance.on('cancel', function (data) {
          if (
            hostedInstance.currentPaymentProduct === 'paypal' ||
            hostedInstance.type === 'paypal'
          ) {
            console.log('HP PayPal v2 cancel', data);
          } else {
            console.log('HP ApplePay cancel');
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hostedInstance]
  );

  useEffect(
    () => {
      if (paymentRequestButtonInstance) {
        paymentRequestButtonInstance.on(
          'paymentAuthorized',
          function (tokenHipay) {
            setApplePayHipayTokenInstance(tokenHipay);
            navigate('/payment');
          }
        );

        paymentRequestButtonInstance.on(
          'paymentUnauthorized',
          function (error) {
            console.log('HF ApplePay paymentUnauthorized');
            console.log(error);
          }
        );

        paymentRequestButtonInstance.on('cancel', function () {
          console.log('HF ApplePay cancel');
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paymentRequestButtonInstance]
  );

  // Function to init an instance
  const initHostedInstance = (type, config) => {
    if (type === 'paymentRequestButton') {
      // If already have an instance, destroy it
      if (paymentRequestButtonInstance) {
        paymentRequestButtonInstance.destroy();
      }

      // Create new instance
      let newInstance = window.hipay.create(type, config);

      // Set new state with this instance
      setPaymentRequestButtonInstance(newInstance);
    } else {
      // If already have an instance, destroy it
      if (hostedInstance) {
        hostedInstance.destroy();
      }

      // Create new instance
      let newInstance = window.hipay.create(type, config);

      // Set new state with this instance
      setInstance(newInstance);
    }
  };

  // Function to destroy the instance
  const destroyHostedInstance = () => {
    // reset instance
    setInstance(null);

    // Reset form validity
    props.updatePaymentFormValidity(false);
  };

  const value = {
    hostedInstance,
    paymentRequestButtonInstance,
    applePayHipayTokenInstance,
    paypalHipayTokenInstance,
    setApplePayHipayTokenInstance,
    setPaypalHipayTokenInstance,
    initHostedInstance,
    destroyHostedInstance
  };

  // Render provider with instance, init & destroy exposed
  return (
    <HostedInstanceContext.Provider value={value}>
      {props.children}
    </HostedInstanceContext.Provider>
  );
}

export default connect(null, {
  updatePaymentFormValidity
})(HostedInstanceProvider);
