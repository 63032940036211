import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { getFrontendSdkDraftConfig } from '../../../selectors/configSelectors';
import { updateFrontendSdkConfig } from '../../../actions/sdkConfigActions';

import RadioGroup from '@mui/material/RadioGroup';
import {
  HiFormControl,
  HiInput,
  HiRadio
} from '@hipay/design-system/components';

function ConfigEnvSdkjs(props) {
  const handleSwitchChange = (event) => {
    props.updateFrontendSdkConfig('environment', event.target.value);
  };

  const handleInputChange = (value) => {
    props.updateFrontendSdkConfig('customUrl', value);
  };

  return (
    <div className="ConfigEnvSdkjs">
      <h2>
        <FormattedMessage id={'environment-sdkjs'} />
      </h2>
      <div className="environment">
        <div className="help">
          <FormattedMessage id={'environment-sdkjs-help-1'} />
          <br />
          <FormattedMessage id={'environment-sdkjs-help-2'} />
        </div>

        <RadioGroup
          value={props.sdk_frontend.environment}
          onChange={handleSwitchChange}
        >
          <HiRadio
            value="production"
            label={props.intl.formatMessage({ id: 'live' })}
          />
          <HiRadio
            value="stage"
            label={props.intl.formatMessage({ id: 'testing' })}
          />
          <HiRadio
            value="custom"
            label={props.intl.formatMessage({ id: 'custom' })}
          />
        </RadioGroup>

        {props.sdk_frontend.environment === 'custom' && (
          <HiFormControl
            className="textField"
            id="custom_sdkjs_url"
            name="custom_sdkjs_url"
            style={{ width: '100%' }}
            label={props.intl.formatMessage({ id: 'url-custom-sdkjs' })}
            helperText={props.intl.formatMessage({
              id: 'helperText-url-custom-sdkjs'
            })}
            error={props.hasSdkFrontendError}
          >
            <HiInput
              value={props.sdk_frontend.customUrl ?? ''}
              onChange={handleInputChange}
              placeholder={'https://example.com'}
            />
          </HiFormControl>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    sdk_frontend: getFrontendSdkDraftConfig(state),
    hasSdkFrontendError: state.appReducer.hasSdkFrontendError
  };
};

export default connect(mapStateToProps, { updateFrontendSdkConfig })(
  injectIntl(ConfigEnvSdkjs)
);
