const defaultPaymentConfig = {
  carousel: {},
  card: {
    fields: {
      cardHolder: {
        uppercase: true,
        helpButton: false
      },
      cardNumber: {
        helpButton: false,
        hideCardTypeLogo: false
      },
      expiryDate: {
        helpButton: false
      },
      cvc: {
        helpButton: true
      }
    },
    one_click: {
      enabled: true,
      cards_display_count: 3,
      cards: JSON.parse(localStorage.getItem('saved_cards')) || []
    }
  },
  paypal: {
    request: {
      amount: 100,
      currency: 'EUR',
      locale: 'fr_FR'
    },
    paypalButtonStyle: {
      color: 'gold',
      shape: 'pill',
      label: 'pay',
      height: 40
    },
    canPayLater: true
  },
  sdd: {
    client_type: 'all',
    fields: {
      client_type: {
        helpButton: false
      },
      gender: {
        helpButton: false
      },
      firstname: {
        helpButton: false,
        uppercase: false
      },
      lastname: {
        helpButton: false,
        uppercase: false
      },
      company: {
        helpButton: false,
        uppercase: false
      },
      company_type: {
        helpButton: false
      },
      bank_name: {
        helpButton: false,
        uppercase: false
      },
      iban: {
        helpButton: false
      }
    }
  },
  paymentRequestButton: {
    request: {
      countryCode: 'FR',
      currencyCode: 'EUR',
      total: {
        label: 'Demo HiPay',
        amount: '100'
      }
    },
    applePayStyle: {
      type: 'plain',
      color: 'black'
    },
    selector: 'payment-request-button-container',
    displayName: 'Demo HiPay'
  },
  display_cancel_button: 0
};

export default defaultPaymentConfig;
