import './EnvWarning.scss';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import SvgIcon from '@mui/material/SvgIcon';

function EnvWarning(props) {
  return (
    props.mode === 'production' && (
      <div className={'EnvWarning'}>
        <SvgIcon component="div">
          <img
            src={`${process.env.PUBLIC_URL}/static/images/danger.svg`}
            alt="danger"
            height="24px"
            width="24px"
          />
        </SvgIcon>

        <p>
          <FormattedMessage id={'production-mode-message'} />
        </p>
      </div>
    )
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    mode: state.sdkConfigReducer.config.client.environment
  };
};

export default connect(mapStateToProps, null)(EnvWarning);
