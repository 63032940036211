import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  getBackendSdkDraftConfig,
  getClientDraftConfig
} from '../../../selectors/configSelectors';
import {
  updateClientConfig,
  updateBackendSdkConfig,
  updateBackendEnvConfig
} from '../../../actions/sdkConfigActions';

import RadioGroup from '@mui/material/RadioGroup';
import Link from '@mui/material/Link';
import SvgIcon from '@mui/material/SvgIcon';
import IconButton from '@mui/material/IconButton';
import {
  HiFormControl,
  HiInput,
  HiRadio
} from '@hipay/design-system/components';
import {
  VpnKey,
  AccountCircle,
  VisibilityOff,
  Visibility
} from '@mui/icons-material';

function ConfigCredentials(props) {
  const [credentialsOpen, setCredentialsOpen] = useState(
    props.client.environment === 'production' ||
      !!props.client.username.length ||
      !!props.sdk_backend.username.length
  );
  const [showPrivatePassword, setShowPrivatePassword] = useState(false);
  const [showPublicPassword, setShowPublicPassword] = useState(false);

  const handleEnvChange = useCallback(
    (event) => {
      props.updateBackendEnvConfig('environment', event.target.value);

      // If production clicked, open credentials
      if (event.target.value === 'production') {
        setCredentialsOpen(true);
      }

      // If other than production clicked & no credentials filled, close credentials
      if (
        event.target.value !== 'production' &&
        !props.client.username &&
        !props.client.password &&
        !props.sdk_backend.username &&
        !props.sdk_backend.password
      ) {
        setCredentialsOpen(false);
      }
    },
    [props]
  );

  const handleClientChange = useCallback(
    (name) => (value) => {
      props.updateClientConfig(name, value);
    },
    [props]
  );

  const handleBackendChange = useCallback(
    (name) => (value) => {
      props.updateBackendSdkConfig(name, value);
    },
    [props]
  );

  const handleBackendUrlsChange = useCallback(
    (name) => (value) => {
      props.updateBackendEnvConfig(name, value);
    },
    [props]
  );

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleShowPrivatePasswordClick = useCallback((event) => {
    setShowPrivatePassword((show) => !show);
  }, []);

  const handleShowPublicPasswordClick = useCallback((event) => {
    setShowPublicPassword((show) => !show);
  }, []);

  return (
    <div className="ConfigCredentials">
      <h2>
        <FormattedMessage id={'environment-api'} />
      </h2>
      <div className="environment">
        <div className="help">
          <FormattedMessage id={'environment-api-help-1'} />
          <br />
          <FormattedMessage id={'environment-api-help-2'} />
        </div>

        <RadioGroup
          className={'radioGroup'}
          value={props.client.environment}
          onChange={handleEnvChange}
        >
          <HiRadio
            value="production"
            label={props.intl.formatMessage({ id: 'live' })}
          />
          <HiRadio
            value="stage"
            label={props.intl.formatMessage({ id: 'testing' })}
          />
        </RadioGroup>

        {props.sdk_backend.environment === 'custom' && (
          <div>
            <div className="formControl">
              <HiFormControl
                id="custom_secure_vault_url"
                name="custom_secure_vault_url"
                label={props.intl.formatMessage({
                  id: 'url-custom-secure_vault'
                })}
                style={{ width: '100%' }}
                helperText={props.intl.formatMessage({
                  id: 'helperText-url-custom'
                })}
              >
                <HiInput
                  value={props.sdk_backend.secure_vault_url}
                  onChange={handleBackendUrlsChange('secure_vault_url')}
                  placeholder={'https://example.com'}
                />
              </HiFormControl>
            </div>

            <div className="formControl">
              <HiFormControl
                id="custom_gateway_url"
                name="custom_gateway_url"
                style={{ width: '100%' }}
                label={props.intl.formatMessage({ id: 'url-custom-gateway' })}
                helperText={props.intl.formatMessage({
                  id: 'helperText-url-custom'
                })}
              >
                <HiInput
                  value={props.sdk_backend.gateway_url}
                  onChange={handleBackendUrlsChange('gateway_url')}
                  placeholder={'https://example.com'}
                />
              </HiFormControl>
            </div>
          </div>
        )}

        {!credentialsOpen && (
          <div className="help">
            <FormattedMessage id={'want-test'} />
            <Link className="link" onClick={() => setCredentialsOpen(true)}>
              <FormattedMessage id={'add-credentials'} />
            </Link>
          </div>
        )}
      </div>

      {credentialsOpen && (
        <div>
          <h2>
            <FormattedMessage id={'api-credentials'} />
          </h2>

          {props.client.environment === 'production' && (
            <div className="warning">
              <SvgIcon component="div">
                <img
                  src={`${process.env.PUBLIC_URL}/static/images/danger.svg`}
                  alt="danger"
                  height="24px"
                  width="24px"
                />
              </SvgIcon>

              <p>
                <FormattedMessage id={'live-mode-required-credentials'} />
              </p>
            </div>
          )}

          <h3>
            <FormattedMessage id={'tokenization'} />
          </h3>

          <div className="tokenization">
            <div className="help">
              <FormattedMessage id={'tokenization-help-1'} />
              <br />
              <FormattedMessage id={'tokenization-help-2'} />
            </div>

            <div className='formControl'>
              <HiFormControl
                className='textField'
                id='public_username'
                name='public_username'
                label={props.intl.formatMessage({ id: 'public-username' })}
                fullWidth={true}
              >
                <HiInput
                  value={props.client.username ?? ''}
                  onChange={handleClientChange('username')}
                  startIcon={AccountCircle}
                  placeholder={props.intl.formatMessage({ id: 'username' })}
                />
              </HiFormControl>
            </div>

            <div className='formControl'>
              <HiFormControl
                className='textField'
                id='public_password'
                name='public_password'
                label={props.intl.formatMessage({ id: 'public-password' })}
                fullWidth={true}
              >
                <HiInput
                  value={props.client.password ?? ''}
                  onChange={handleClientChange('password')}
                  startIcon={VpnKey}
                  placeholder={props.intl.formatMessage({ id: 'password' })}
                  type={showPublicPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        style={{ pointerEvents: 'all' }}
                        onClick={handleShowPublicPasswordClick}
                        onMouseDown={handleMouseDownPassword}
                        size={'small'}
                      >
                        {showPublicPassword ? (
                          <VisibilityOff fontSize={'small'} />
                        ) : (
                          <Visibility fontSize={'small'} />
                        )}
                      </IconButton>
                    )
                  }}
                />
              </HiFormControl>
            </div>
          </div>

          <h3>
            <FormattedMessage id={'transaction'} />
          </h3>
          <div className="transaction">
            <div className="help">
              <FormattedMessage id={'transaction-help'} />
            </div>

            <div className="formControl">
              <HiFormControl
                className="textField"
                id="private_username"
                name="private_username"
                label={props.intl.formatMessage({ id: 'private-username' })}
                style={{ width: '100%' }}
              >
                <HiInput
                  value={props.sdk_backend.username ?? ''}
                  onChange={handleBackendChange('username')}
                  placeholder={props.intl.formatMessage({ id: 'username' })}
                  startIcon={AccountCircle}
                />
              </HiFormControl>
            </div>

            <div className="formControl">
              <HiFormControl
                className="textField"
                id="private_password"
                name="private_password"
                label={props.intl.formatMessage({ id: 'private-password' })}
                style={{ width: '100%' }}
              >
                <HiInput
                  value={props.sdk_backend.password ?? ''}
                  onChange={handleBackendChange('password')}
                  startIcon={VpnKey}
                  placeholder={props.intl.formatMessage({ id: 'password' })}
                  type={showPrivatePassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        style={{ pointerEvents: 'all' }}
                        onClick={handleShowPrivatePasswordClick}
                        onMouseDown={handleMouseDownPassword}
                        size={'small'}
                      >
                        {showPrivatePassword ? (
                          <VisibilityOff fontSize={'small'} />
                        ) : (
                          <Visibility fontSize={'small'} />
                        )}
                      </IconButton>
                    )
                  }}
                />
              </HiFormControl>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    client: getClientDraftConfig(state),
    sdk_backend: getBackendSdkDraftConfig(state)
  };
};

export default connect(mapStateToProps, {
  updateClientConfig,
  updateBackendSdkConfig,
  updateBackendEnvConfig
})(injectIntl(ConfigCredentials));
