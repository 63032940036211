import './Home.scss';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FakeCheckoutStep from '../../components/FakeCheckoutStep/FakeCheckoutStep';
import PaymentDetails from '../PaymentDetails/PaymentDetails';
import Cart from '../Cart/Cart';
import EnvWarning from '../../components/EnvWarning/EnvWarning';
import OrderButton from '../../components/OrderButton/OrderButton';
import HostedPageButton from '../../components/HostedPageButton/HostedPageButton';
import {
  getFullPaymentConfig,
  getPaymentModeConfig
} from '../../selectors/configSelectors';
import { useHostedInstance } from '../../contexts/HostedInstanceContext';

function Home(props) {
  const { hostedInstance } = useHostedInstance();

  const needPayButton =
    (props.payment_mode.mode === 'HP' &&
      hostedInstance?.currentPaymentInstance?.needPayButton) ||
    (props.payment_mode.mode === 'HF' && hostedInstance?.needPayButton);
  return (
    <div className="Home page-content">
      <div className="panel-left">
        <div className="panel-left-wrapper">
          <FakeCheckoutStep name="billing-and-shipping-information" />
          <FakeCheckoutStep name="shipping-method" />
          <PaymentDetails />
          <EnvWarning />
          {props.payment_mode.mode === 'HPage' ? (
            <HostedPageButton />
          ) : (
            needPayButton && <OrderButton />
          )}
        </div>
      </div>
      <div className="panel-right">
        <Cart />
      </div>
    </div>
  );
}

Home.propType = {
  // Boolean - Payment in progress when order button clicked
  paymentInProgress: PropTypes.bool.isRequired,
  payment_mode: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    paymentInProgress: state.appReducer.paymentInProgress,
    payment_mode: getPaymentModeConfig(state),
    payment: getFullPaymentConfig(state)
  };
};

export default connect(mapStateToProps, null)(Home);
