import './CartItem.scss';

import { FormattedNumber } from 'react-intl';

function CartItem(props) {
  return (
    <div className="CartItem">
      <div className="image">
        <img
          src={`${process.env.PUBLIC_URL}/static/images/items/${props.imageUrl}`}
          alt={props.name}
        />
      </div>

      <div className="name">{props.name}</div>

      <div className="amount">
        <FormattedNumber
          value={props.price * props.quantity}
          // eslint-disable-next-line
          style={'currency'}
          currency={props.currency}
        />
      </div>

      <div className="description">
        {props.name !== 'shipping' && (
          <div className="quantity">
            <FormattedNumber
              value={props.price}
              // eslint-disable-next-line
              style={'currency'}
              currency={props.currency}
            />
            {' x '}
            {props.quantity}
          </div>
        )}

        {props.name === 'shipping' && (
          <div className="address">
            <div>John Doe</div>
            <div>15 rue de la paix</div>
            <div>75015 PARIS</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CartItem;
