import './OrderButton.scss';

import { connect } from 'react-redux';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { getPaymentModeDraftConfig } from '../../selectors/configSelectors';

import { HiButton } from '@hipay/design-system/components';

function OrderButton(props) {
  let navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate('/payment');
  }, [navigate]);

  return (
    <div className="OrderButton">
      <HiButton
        className="button-base"
        fullWidth
        color="success"
        disabled={
          !props.paymentFormIsValid && props.payment_mode.mode !== 'HPage'
        }
        onClick={handleClick}
      >
        <FormattedMessage id={'place-order'} />
      </HiButton>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    paymentFormIsValid: state.appReducer.paymentFormIsValid,
    payment_mode: getPaymentModeDraftConfig(state)
  };
};

export default connect(mapStateToProps, null)(OrderButton);
