import './HostedPageButton.scss';

import React, { useEffect, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { HiButton } from '@hipay/design-system/components';
import HostedPagePayment from '../../containers/HostedPagePayment/HostedPagePayment';
import { connect } from 'react-redux';
import { isHPageIFrame } from '../../selectors/configSelectors';

const HostedPageButton = (props) => {
  let navigate = useNavigate();
  const [showIFrame, setShowIFrame] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [transactionData, setTransactionData] = useState(null);

  const handleClick = useCallback(() => {
    if (!props.iframe) {
      navigate('/hpage_payment');
    } else {
      setShowIFrame(true);
    }
  }, [props.iframe, navigate]);

  const receiver = new BroadcastChannel('iframe');

  receiver.onmessage = (e) => {
    if (e.origin !== process.env.REACT_APP_FRONTEND_URL) return;
    if (e.data.state === 'complete' && e.data.params) {
      setTransactionData(e.data.params);
    }
    if (e.data.state === 'cancel') {
      setShowIFrame(false);
    }
  };

  receiver.onmessageerror = (err) => {
    console.error(err);
  };

  useEffect(() => {
    if (transactionData?.reference) {
      navigate('/hpage_payment', {
        state: {
          finalPaymentData: paymentData,
          finalTransactionData: transactionData
        }
      });
    }
  }, [paymentData, transactionData, navigate]);

  return (
    <div className="HostedPageButton">
      {props.iframe && showIFrame ? (
        <HostedPagePayment
          setPaymentData={setPaymentData}
          setTransactionData={setTransactionData}
        />
      ) : (
        <>
          <div className="hpage-payment-info">
            {props.iframe ? (
              <FormattedMessage id={'hpage-payment-info-iframe'} />
            ) : (
              <FormattedMessage id={'hpage-payment-info'} />
            )}
          </div>
          <HiButton
            className="button-base"
            fullWidth
            color="success"
            disabled={showIFrame}
            onClick={handleClick}
          >
            <FormattedMessage id={'place-order'} />
          </HiButton>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    iframe: isHPageIFrame(state)
  };
};

export default connect(mapStateToProps, {})(HostedPageButton);
