import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import appReducer from './reducers/appReducer';
import intlReducer from './reducers/intlReducer';
import sdkConfigReducer from './reducers/sdkConfigReducer';

import sdkLoader from './middlewares/sdkLoader';

const store = configureStore({
  reducer: {
    intl: intlReducer,
    appReducer: appReducer,
    sdkConfigReducer
  },
  middleware: [
    sdkLoader,
    ...getDefaultMiddleware({ serializableCheck: false })
  ],
  devTools: process.env.NODE_ENV !== 'production'
});

export default store;
