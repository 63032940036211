import React from 'react';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { FormattedMessage } from 'react-intl';
import SvgIcon from '@mui/material/SvgIcon';
import JSONPretty from 'react-json-pretty';

const HostedPageStep = ({
  title,
  data,
  error,
  errorText,
  help,
  waitingText
}) => {
  return (
    <>
      <div className="step-title">
        <div className="title">
          <div />
          <div>
            <FormattedMessage id={title} />
          </div>
          <div>
            {data ? (
              <SvgIcon component="div">
                <img
                  src={`${process.env.PUBLIC_URL}/static/images/tick.svg`}
                  alt="ok"
                  height="30px"
                  width="30px"
                />
              </SvgIcon>
            ) : error ? (
              <SvgIcon component="div">
                <img
                  src={`${process.env.PUBLIC_URL}/static/images/error.svg`}
                  alt="error"
                  height="40px"
                  width="40px"
                />
              </SvgIcon>
            ) : (
              <LoadingSpinner />
            )}
          </div>
        </div>
      </div>
      {data ? (
        <>
          <div className="step-subtitle">
            <FormattedMessage id={help} />
          </div>
          <div className="step-result">
            <div className="step-result-code">
              <JSONPretty data={data} />
            </div>
          </div>
        </>
      ) : error ? (
        <>
          <div className="step-subtitle">
            <FormattedMessage id={errorText} />
          </div>
          <div className="step-result">
            <div className="step-result-code">
              <JSONPretty data={error} />
            </div>
          </div>
        </>
      ) : (
        <div className="step-subtitle">
          <FormattedMessage id={waitingText} />
        </div>
      )}
    </>
  );
};

export default HostedPageStep;
